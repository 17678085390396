import React from "react"
import PropTypes from "prop-types"

export const PlayIcon = ({ className, height, width }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={height}
        height={width}
        fill="#f7f5f4"
        viewBox="0 0 32 32"
        tabIndex={0}
        className={className ? className : null}
        type="image/svg+xml"
        data-svg-url="/etc/ui/lipton/clientlibs/core/core/svgs/sprites/play-video.svg"
    >
        <path d="M23.193 16.694l-11.069 5.805a.999.999 0 01-1.463-.885V10.14a.999.999 0 011.46-.887l-.006-.003 11.069 5.67a1 1 0 01.013 1.771l-.005.003zM15.844.029C7.105.029-.004 7.138-.004 15.877s7.109 15.848 15.848 15.848 15.848-7.109 15.848-15.848S24.583.029 15.844.029z"></path>
    </svg>
);

export const YoutubePlay = ({ className, height, width }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={height}
      height={width}
      viewBox="0 0 32 32"
      tabIndex={0}
      className={className ? className : null}
    >
      <circle cx="16" cy="16" r="15" fill="transparent" stroke="#f7f5f4" strokeWidth="2" />
      <polygon points="12,10 12,22 22,16" fill="#f7f5f4" />
    </svg>
  );
  

export const SearchIcon = ({ className, height, width }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={height}
        height={width}
        fill="#f7f5f4"
        viewBox="0 0 32 32"
        tabIndex={-1}
        className={className}
        type="image/svg+xml"
        data-svg-url="/etc/ui/lipton/clientlibs/core/core/svgs/sprites/search.svg"
    >
        <path d="M19.341 26.022c-7.209 0-13.011-5.802-13.011-13.011S12.308 0 19.341 0s13.011 5.802 13.011 13.011-5.802 13.011-13.011 13.011zm0-22.506c-5.275 0-9.495 4.22-9.495 9.495s4.22 9.494 9.495 9.494 9.494-4.22 9.494-9.495v-.039a9.457 9.457 0 00-9.456-9.456h-.041.002zM7.258 22.25l2.486 2.486-7.335 7.335-2.486-2.486 7.335-7.335z"></path>

    </svg>
)


export const GlobIcon = ({ className, height, width }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={height}
        height={width}
        fill="#000000"
        viewBox="0 0 32 32"
        tabIndex={-1}
        className={className}
        type="image/svg+xml"
        data-svg-url="/etc/ui/lipton/clientlibs/core/core/svgs/sprites/flag-globe.svg"
    >
        <path d="M15.349 29.799h-.019a13.77 13.77 0 01-7.966-2.523l.044.029.259-.373.727-2.509.636-.627.182-1.075.909-2.18-1.091-1.046-2.636-1.344-2.091-1.971s-1.364-.269-1.818-.269-.454-.448-.454-.448l.182-2.509.818-1.075.091-.806 2.727-1.882.545.179s1.091 0 1.636-.269.546-1.075.546-1.075l-.455-.986-.397-2.113a13.589 13.589 0 013.492-1.655l.098-.026-.283.838v.806l5.363-1.882.191-.301c2.218.198 4.238.892 5.998 1.969l-.06-.034-1.947.785s.454.896 0 1.344-3.182 1.702-3.727 1.881c-.546.179-1.518 1.165-.85 1.702s.759 1.075.759 1.075-1.727 0-2.273.717c-.545.717-2.182 1.971-1.727 2.957s1.273 2.598 2.091 2.867 2.545-.448 2.636.179c.091.627.182 1.822.182 2.389s-.091 2.986-.182 3.524c-.091.538.182 2.329.909 2.329s4.182-1.702 4.364-2.867c.182-1.165.454-2.837.818-2.986s2.273-1.046 2.273-1.046.727-1.434.727-1.792c0-.358-1.727-2.688-1.727-3.315s-.091-.896.636-.896c0 0 .636.269 1.364.896s1.273.896 1.273.896h.963c.022.311.037.624.037.941 0 7.485-6.178 13.574-13.772 13.574zm15.095-14.151a15.217 15.217 0 00-6.18-11.331l-.042-.03a15.004 15.004 0 00-6.733-2.766l-.082-.01a14.867 14.867 0 00-2.168-.156h-.001C6.822 1.355 0 8.177 0 16.593c0 5.149 2.557 9.698 6.467 12.457a15.042 15.042 0 008.754 2.782h.018-.001c8.416 0 15.238-6.822 15.238-15.238 0-.317-.013-.632-.032-.944z"></path>

    </svg>
)


export const FacebookIcon = ({ className, height, width, color = "inherit" }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        style={{ pointerEvents: "none" }}
        height={height}
        fill={color}
        viewBox="0 0 32 32"
        tabIndex={-1}
        className="c-svg c-social-sharing-icons lazyloaded"
        type="image/svg+xml"
        data-svg-url="/etc/ui/lipton/clientlibs/core/core/svgs/sprites/facebook.svg"
    >
        <path d="M15.7 0C7 0 0 7.2 0 16s7 16 15.7 16 15.7-7.2 15.7-16c-.1-8.8-7.1-16-15.7-16zM19 16h-2.2v8h-3.3v-8H12v-2.8h1.5v-1.8c0-1.3.6-3.4 3.3-3.4h2.4v2.7h-1.7c-.3 0-.7.1-.7.8v1.7h2.5L19 16z"></path>
    </svg>
)

export const MailIcon = ({ className, height, width, color = "inherit" }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        style={{ pointerEvents: "none" }}
        height={height}
        fill={color}
        viewBox="0 0 32 32"
        tabIndex={-1}
        className="c-svg c-social-sharing-icons lazyloaded"
        type="image/svg+xml"
        data-svg-url="/etc/ui/lipton/clientlibs/core/core/svgs/sprites/email.svg"
    >
        <path d="M15.7 0C7 0 0 7.2 0 16s7 16 15.7 16 15.7-7.2 15.7-16c-.1-8.8-7.1-16-15.7-16zM23 10.7l-7.4 5.5-7.4-5.5H23zm.1 10H8v-8.5l7.5 5.5 7.5-5.5v8.5z"></path>
    </svg>
)

export const TwitterIcon = ({ className, height, width, color = "inherit" }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        style={{ pointerEvents: "none" }}
        height={height}
        fill={color}
        viewBox="0 0 32 32"
        tabIndex={-1}
        className="c-svg c-social-sharing-icons lazyloaded"
        type="image/svg+xml"
        data-svg-url="/etc/ui/lipton/clientlibs/core/core/svgs/sprites/twitter.svg"
    >
        <path d="M15.7 0C7 0 0 7.2 0 16s7 16 15.7 16 15.7-7.2 15.7-16c-.1-8.8-7.1-16-15.7-16zm6.2 12.7v.4c0 4.3-3.2 9.3-9.1 9.3-1.8 0-3.5-.5-4.9-1.5h.8c1.5 0 2.9-.5 4-1.4-1.4 0-2.6-1-3-2.3.2 0 .4.1.6.1.3 0 .6 0 .8-.1-1.5-.3-2.6-1.6-2.6-3.2.4.2.9.4 1.5.4-.9-.6-1.4-1.6-1.4-2.7 0-.6.2-1.2.4-1.7 1.6 2 4 3.3 6.6 3.4-.1-.2-.1-.5-.1-.7 0-1.8 1.4-3.3 3.2-3.3.9 0 1.8.4 2.3 1 .7-.1 1.4-.4 2-.8-.2.8-.7 1.4-1.4 1.8.7-.1 1.3-.3 1.8-.5-.3.8-.9 1.4-1.5 1.8z"></path>
    </svg>
)

export const ArrowHead = ({ }) => (
    <svg
        width="40"
        height="40"
        viewBox="0 0 10 6"
        fill="black"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path fill="black" d="M9.65575 0.896124L4.99975 5.55212L0.343751 0.896123L1.01575 0.224122L4.99975 4.20812L8.98375 0.224123L9.65575 0.896124Z"/>
    </svg>
)

PlayIcon.propTypes = {
    //   className: PropTypes.string.isRequired,
    className: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
}
SearchIcon.propTypes = {
    className: PropTypes.string,
    //   className: PropTypes.string.isRequired,
    height: PropTypes.number,
    width: PropTypes.number,
}
GlobIcon.propTypes = {
    className: PropTypes.string,
    // className: PropTypes.string.isRequired,
    height: PropTypes.number,
    width: PropTypes.number,
    color: PropTypes.string
}

FacebookIcon.propTypes = {
    className: PropTypes.string,
    // className: PropTypes.string.isRequired,
    height: PropTypes.number,
    width: PropTypes.number,
    color: PropTypes.string
}
MailIcon.propTypes = {
    className: PropTypes.string,
    // className: PropTypes.string.isRequired,
    height: PropTypes.number,
    width: PropTypes.number,
}
TwitterIcon.propTypes = {
    className: PropTypes.string,
    // className: PropTypes.string.isRequired,
    height: PropTypes.number,
    width: PropTypes.number,
}