import React, { useEffect, useState } from "react"
import { useSiteQuery, useUrl, useLocale, useLocalPath, useDictionaryQuery } from "../../../hooks"
import { useSearchQuery } from "../../../hooks/layout/useSearchQuery";

import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { GlobIcon } from "../../../svgs/play"
import * as styles from "./footer.module.scss"
import CookiePopup from "../CookiePopup/CookiePopup"
import { RichText } from "../RichText/RichText";


const Footer = () => {
    const {
        footerContactUsLink,
        footerSiteLanguages,
        footerMenuItems,
        footerSocialIcons,
        footerUnileverLogo,
        copyrightText,
        footerCopyrightDescription,
    } = useSiteQuery()
    const{companyInfo}=useSearchQuery()
    const baseUrl = useUrl();
    const copyRight = footerCopyrightDescription.footerCopyrightDescription?.split("\\n ");
    const externalLink = (link) => {
        return link && (link.split('/').includes('https:') || link.split('/').includes('http:'));
    }


    const [privacyOpen, setPrivacyOpen] = useState(false)
    const { cookiepopup } = useSearchQuery()

    const events = ['scroll', 'mousemove', 'touchstart'];
    const [svgInteraction, setSvgInteraction] = useState(false)
    const initButton = () => {
        setSvgInteraction(true)

        events.forEach(event => {
            window.removeEventListener(event, initButton)
        })
    }
    if (typeof window !== 'undefined') {
        events.forEach(event => {
            window.addEventListener(event, initButton)
        })
    }

    const locale = useLocale()
    const date = new Date();
    const CURRENT_YEAR = date.getFullYear();

   
    return (
        <footer className={`styles.footer_module`}>
            <div className={`${styles.footer_module__top} spacer__01`}>
                <div className={styles.col_1}>
                    <GlobIcon className={null} />
                    {
                        footerContactUsLink[0].slug.includes('http')
                            ? <a href={footerContactUsLink[0].slug}>
                                {footerContactUsLink[0].title}
                            </a>
                            : <Link to={useLocalPath(footerContactUsLink[0].slug)}>
                                {footerContactUsLink[0].title}
                            </Link>
                    }
                    {locale === 'tr-TR' && companyInfo && 
                    <div className={styles.addressDiv}>
                        <RichText content={companyInfo}/>
                    </div>}
                    {
                        (locale === 'en-EG' || locale === 'ar-EG' || locale === 'en-AE' || locale === 'ar-AE') &&
                        <p>
                            <Link
                                to={`${footerSiteLanguages[0].slug}`}
                                className="btn__secondary"
                            >
                                العربية
                            </Link>
                            <Link
                                to={`${footerSiteLanguages[1].slug}`}
                                className="btn__secondary">
                                English
                            </Link>
                        </p>
                    }
                    {
                        (locale === 'en-CA' || locale === 'fr-CA') &&
                        <p>
                            <Link
                                to={`${footerSiteLanguages[0].slug}`}
                                className="btn__secondary"
                            >
                                Français
                            </Link>
                            <Link
                                to={`${footerSiteLanguages[1].slug}`}
                                className="btn__secondary">
                                English
                            </Link>
                        </p>
                    }

                    {
                        (locale === 'nl-BE' || locale === 'fr-BE') &&
                        <p>
                            <Link
                                to={`${footerSiteLanguages[0].slug}`}
                                className="btn__secondary"
                            >
                                FR
                            </Link>
                            <Link
                                to={`${footerSiteLanguages[1].slug}`}
                                className="btn__secondary">
                                NL
                            </Link>
                        </p>
                    }

                </div>
                <div className={styles.col_2}>
                    <ul className={styles.footer__nav}>
                        {footerMenuItems.map((items: any, index: number) => {
                            const { title, slug, pdflink } = items;

                            if (externalLink(slug)) {
                                return (
                                    <li key={index}>
                                        <a href={slug} target="_blank">
                                            {title}
                                        </a>
                                    </li>
                                );
                            } else {
                                const pdfLinkUrl = pdflink?.file?.url;
                                return (
                                    <li key={index}>
                                        {pdfLinkUrl ? (
                                            <a href={pdfLinkUrl} target="_blank">
                                                {title}
                                            </a>
                                        ) : (
                                            slug.startsWith('/') ? (
                                                <Link to={useLocalPath(slug)}>
                                                    {title}
                                                </Link>
                                            ) : (
                                                <Link to={useLocalPath(slug)}>
                                                {title}
                                            </Link>
                                            )
                                        )}
                                    </li>
                                );
                            }
                        })}
                        <li>
                            <p className={styles.privacyPopupLink} onClick={e => { setPrivacyOpen(true) }}>{cookiepopup}</p>
                            <div className={`${styles.wrapper} ${privacyOpen ? styles.privacyOpen : ''}`} >
                                {privacyOpen ? <CookiePopup setOpen={setPrivacyOpen} /> : null}
                            </div>
                        </li>
                    </ul>

                </div>
                {
                    svgInteraction &&
                    <div className={styles.col_3}>
                        <ul className={`${styles.social__icons} ${`styles.d_flex`}}`}>
                            {footerSocialIcons?.map((items: any, index: number) => {
                                const { title, slug, image } = items
                                if (externalLink(slug)) {
                                    return (
                                        <li key={index}>
                                            <a href={`${slug}`}>
                                                <Img
                                                    fluid={image[0].fluid}
                                                    className={title}
                                                    alt={title}
                                                ></Img>
                                            </a>
                                        </li>
                                    )
                                } else {
                                    return (
                                        <li key={index}>
                                            <Link to={useLocalPath(`/${slug}`)}>
                                                <Img
                                                    fluid={image[0].fluid}
                                                    className={title}
                                                    alt={title}
                                                ></Img>
                                            </Link>
                                        </li>
                                    )
                                }
                            })}
                        </ul>
                    </div>
                }
            </div>
            <div className={styles.footer_module__bottom}>
                <a href={footerUnileverLogo.slug}>
                    <Img
                        fluid={footerUnileverLogo.image[0].fluid}
                        className={footerUnileverLogo.title}
                        alt={footerUnileverLogo.title}
                    ></Img>
                </a>
                <div>{copyrightText &&
                    <p className={styles.footerSmallDesc}>{`${copyrightText} ${CURRENT_YEAR}`}</p>
                }
                    {copyRight[0] &&
                        <p className={styles.footerSmallDesc}>{copyRight[0]}</p>
                    }

                    {copyRight[1] &&
                        <p className={styles.footerSmallDesc}>{copyRight[1]}</p>
                    }

                </div>


            </div>
        </footer>
    )
}

export default Footer
