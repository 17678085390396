import React, { useEffect, useState } from 'react';

import '../../styles/_globals.scss';
import '../../styles/_typography.scss';

// import 'swiper/swiper.scss';

// import { useLocation } from '@reach/router';
import { useLocale, useSiteQuery } from '../../hooks';

// import gsap from 'gsap';
import loadable from '@loadable/component'
import Header from './Header/Header'
import Footer from './Footer/Footer';

// const Header = loadable(() => import('./Header/Header'))
// const Footer = loadable(() => import('./Footer/Footer'))

// const Newsletter = loadable(() => import('../Layout/Newsletter/Newsletter'))
const ButtonTop = loadable(() => import('../button/button-top/ButtonTop'))
const SignUp = loadable(() => import('../Layout/SignUp/SignUp'))

const Layout = ({ children }) => {

    const locale = useLocale()

    const [interaction, setInteraction] = useState(false)
    const events = ['scroll', 'mousemove', 'touchstart'];
    const initButton = () => {
        setInteraction(true)

        events.forEach(event => {
            window.removeEventListener(event, initButton)
        })
    }
    if (typeof window !== 'undefined') {
        events.forEach(event => {
            window.addEventListener(event, initButton)
        })
    }

    useEffect(() => {
        if (locale === 'en-US')
            document.documentElement.style.setProperty('--global-color', "#fade4b")
        return () => {
            if (locale === 'fr')
                document.documentElement.style.removeProperty('--global-color')
        }
    }, [])
    // const refLayout = useRef();
    // useEffect(() => {
    //     gsap.fromTo(refLayout.current, { opacity: 0 }, { duration: 0.3, opacity: 1, delay: 0.5 });
    // }, [])
    return (
        <div>
            <Header />
            {/* <Newsletter /> */}
            <SignUp />
            {
                interaction &&
                <>
                    <ButtonTop />
                </>
            }
            <main className="main">{children}</main>
            <Footer />
        </div>
    )
}

export default Layout;
