// extracted by mini-css-extract-plugin
export var addressDiv = "footer-module--address-div--a5c1b";
export var col_1 = "footer-module--col_1--aec7a";
export var col_2 = "footer-module--col_2--d60ed";
export var col_3 = "footer-module--col_3--fd533";
export var footer = "footer-module--footer--34771";
export var footerSmallDesc = "footer-module--footer-small-desc--5a0a0";
export var footer__nav = "footer-module--footer__nav--0ea48";
export var footer__social_image = "footer-module--footer__social_image--0b8e5";
export var footer__socials = "footer-module--footer__socials--4ef8d";
export var footer_module__bottom = "footer-module--footer_module__bottom--c71f2";
export var footer_module__top = "footer-module--footer_module__top--fa5cf";
export var privacyOpen = "footer-module--privacy-open--2f715";
export var privacyPopupLink = "footer-module--privacy-popup-link--7534b";
export var social__icons = "footer-module--social__icons--01025";
export var wrapper = "footer-module--wrapper--b5217";