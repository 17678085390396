import React, { useEffect } from "react"
import { useLocale } from "../../../hooks"
import _ from "lodash"
import { cookieLang, userCentric } from "../../../utils/mapping"
import { useLocation } from "@reach/router"

const Scripts = () => {
  const location = useLocation()

  const events = [
    "keypress",
    "mousemove",
    "touchstart",
    "touchmove",
    "click",
    "scroll",
  ]
  const locale = useLocale()
  const language = cookieLang[locale]
  const settingId = userCentric

  const cookieScriptInit = () => {
    const script = document.createElement("script")
    script.id = "usercentrics-cmp"
    script.src = "https://app.usercentrics.eu/browser-ui/latest/loader.js"
    script.setAttribute("data-settings-id", settingId[locale])
    script.setAttribute("data-language", language)
    script.defer = true
    document.head.appendChild(script)
  }

  const gtag = () => {
    const script = document.createElement("script")
    script.id = "script-gtag"
    script.innerHTML = `window.dataLayer = window.dataLayer || [];
        function gtag() {
            dataLayer.push(arguments);
        }
        gtag("consent", "default", {
            ad_user_data: "denied",
            ad_personalization: "denied",
            ad_storage: "denied",
            analytics_storage: "denied",
            wait_for_update: 1000
        });`
    script.async = true
    document.head.appendChild(script)
  }

  //const gtmId = "GTM-WCBFC6T"

  // const gtm = () => {
  //   const script = document.createElement("script")
  //   script.setAttribute(
  //     "src",
  //     `https://www.googletagmanager.com/gtm.js?id=${gtmId}`
  //   )
  //   script.async = true
  //   document.head.appendChild(script)
  // }

  // const debugBearScript = () => {
  //   const script = document.createElement("script")
  //   script.src = "https://cdn.debugbear.com/nDV3ktsYhX1F.js"
  //   script.async = true
  //   document.head.appendChild(script)
  // }

  const gtm = () => {
    const script = document.createElement("script")
    script.setAttribute("id", "script-gtm")
    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-WCBFC6T');`
    document.head.appendChild(script)
    script.async = true;
    const noScript = document.createElement("noscript")
    noScript.setAttribute("id", "noscript-gtm")
    noScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WCBFC6T" height="0" width="0" style="display:none;visibility:hidden"></iframe>`
    document.body.appendChild(noScript)
}

  const bazaarVoice = () => {
    const script = document.createElement("script")
    script.id = "script-bv"
    script.src =
      "https://apps.bazaarvoice.com/deployments/lipton-tea/main_site/production/en_US/bv.js" // Replace with appropriate environment URL
    script.async = true
    document.head.appendChild(script)
  }

  //  const embedCuralateScript = () => {
  //   const craluateScript = document.createElement("script")
  //   craluateScript.innerHTML = `
  //           var CRL8_SITENAME = 'lipton-hrz117';
  //           !function(){
  //               var e=window.crl8=window.crl8||{},n=!1,i=[];
  //               e.ready=function(e){
  //                   n?e():i.push(e)
  //               },
  //               e.pixel=e.pixel||function(){
  //                   e.pixel.q.push(arguments)
  //               },
  //               e.pixel.q=e.pixel.q||[];
  //               var t=window.document,
  //                   o=t.createElement("script"),
  //                   c=e.debug||-1!==t.location.search.indexOf("crl8-debug=true")?"js":"min.js";
  //               o.async=!0,
  //               o.src=t.location.protocol+"//edge.curalate.com/sites/"+CRL8_SITENAME+"/site/latest/site."+c,
  //               o.onload=function(){
  //                   n=!0,
  //                   i.forEach(function(e){
  //                       e()
  //                   })
  //               };
  //               var r=t.getElementsByTagName("script")[0];
  //               r.parentNode.insertBefore(o,r.nextSibling)
  //           }();
  //       `

  //   document.head.appendChild(craluateScript)
  // }

  const priceSpiderScriptUrl = "//cdn.pricespider.com/1/lib/ps-widget.js"

  const handleEvent = () => {
    if (locale === "en-US") {
      if (!document.getElementById("script-price-spider")) {
        const script = document.createElement("script")
        script.id = "script-price-spider"
        script.src = priceSpiderScriptUrl
        script.async = true
        document.head.appendChild(script)
        // Remove event listener after first injection
        window.removeEventListener("click", handleEvent)
      }

      if (!document.getElementById("script-bv")) bazaarVoice()

      // embedCuralateScript()
    }

    if (!document.getElementById("script-gtag")) gtag()

    if (!document.getElementById("script-gtm")) gtm()

    if(!document.getElementById("usercentrics-cmp")) cookieScriptInit()
    // debugBearScript()
  }

  useEffect(() => {
    // Combine event listeners for efficiency
    const eventListener = () => handleEvent()
    events.forEach(event => window.addEventListener(event, eventListener))

    return () => {
      events.forEach(event => window.removeEventListener(event, eventListener))
    }
  }, [location])

  return <></>
}

export default Scripts
