import React, { Children } from "react"
import {
    ContentfulRichTextGatsbyReference,
    renderRichText,
    RenderRichTextData,
} from "gatsby-source-contentful/rich-text"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { Options } from "@contentful/rich-text-react-renderer"
import { Bold, Heading1, Heading2, Heading3, Heading4, Heading5, Heading6, Text,} from "./Markdown"
import { GatsbyImage } from "gatsby-plugin-image"

type BlogBodyProps = {
    content: RenderRichTextData<ContentfulRichTextGatsbyReference>
}

export const RichText = ({ content }: BlogBodyProps) => {
    const options: Options = {
        renderMark: {
            [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
            [MARKS.CODE]: (text) => <span className="richTextNotes">{text}</span>,
            [MARKS.SUPERSCRIPT]: (text) =>  <sup className="richTextNotes">{text}</sup>,
            [MARKS.SUBSCRIPT]: (text) => <sub className="richTextNotes">{text}</sub>,


        },
        renderNode: {
            [BLOCKS.HEADING_1]: (node, children) => <Heading1>{children}</Heading1>,
            [BLOCKS.HEADING_2]: (node, children) => <Heading2>{children}</Heading2>,
            [BLOCKS.HEADING_3]: (node, children) => <Heading3>{children}</Heading3>,
            [BLOCKS.HEADING_4]: (node, children) => <Heading4>{children}</Heading4>,
            [BLOCKS.HEADING_5]: (node, children) => <Heading5>{children}</Heading5>,
            [BLOCKS.HEADING_6]: (node, children) => <Heading6>{children}</Heading6>,
            [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
            [INLINES.HYPERLINK]: (node, children) => {
                const { uri } = node.data
                return (
                    uri.split('/')[2] === 'www.lipton.com' ?
                        (<a href={uri} className="underline" >
                            {children}
                        </a>)
                        :
                        (<a href={uri} className="underline" target='blank'>
                            {children}
                        </a>)
                )
            },
            [BLOCKS.EMBEDDED_ASSET]: ({
                data: {
                    target
                }
            }, children) => {
                return (
                    <GatsbyImage
                        image={target.gatsbyImageData}
                        alt={target.image}
                        className="rich-text-image"
                    />
                )

            }
        },
    }

    return (
        <div className="blog-body">{renderRichText(content, options)}</div>
    )
}