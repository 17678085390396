import React from "react";
import { useLocale } from "..";
import { useStaticQuery, graphql } from 'gatsby'
export const useSearchQuery = () => {
    const locale = useLocale();
    const {
        allContentfulGlobalSite2: { edges }
    } = useStaticQuery(
        graphql`
            query globalSite2{
                allContentfulGlobalSite2 {
                    edges {
                        node {
                            node_locale
                            title
                            searchBar
                            lookingFor
                            searchButton
                            all
                            articleButton
                            productsButton
                            recipesButton
                            slug
                            showingheading
                            searchResults
                            sitemapSeoTitle
                            potassium
                            addedSugar
                            downloadpdf
                            pdfSlug
                            questionandAnswer
                            ratingsandReview
                            purposeHeading
                            fullcartheading
                            fiber
                            polyunsaturatedFat
                            monounsaturatedFat
                            dietaryExchanges
                            headerWidget {
                              title
                              slug
                            }
                            sitemapSeoDescription {
                              sitemapSeoDescription
                            }
                            wtbMetaName
                            wtbMetaContent
                            storeLocatorMetaContent
                            pdfDnd
                            cookiepopup
                            crmPreviewImages {
                                title
                                fluid(maxWidth: 2100, quality: 60) {
                                  ...GatsbyContentfulFluid_withWebp_noBase64
                                }
                            }
                            contactUsContentPart1 {
                                raw
                            }
                            contactUsContentPart2 {
                                raw
                            }
                            contactUsButtonText
                            companyInfo{
                                raw
                            }
                        }
                    }
                }
            }
       `
    )

    const localeData = locale
        ? edges.filter(({ node }) => node.node_locale === locale)
        : edges.filter(({ node }) => node.node_locale === 'en-EG')
    const site = localeData.reduce((acc, val) => ({ ...acc, ...val.node }), {})
    return site

}