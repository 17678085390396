module.exports = {
    mapLocale: {
        'en-GB': 'uk/en',
        de: 'de/de',
        'en-US': 'us/en',
        'en-CA': 'ca/en',
        'fr-CA': 'ca/fr',
        'tr-TR': 'tr',
    },
    cookieLang: {
        'en-GB': 'en',
        de: 'de',
        'en-US': 'en',
        'en-CA': 'en',
        'fr-CA': 'fr',
        'es-US': 'es',
        'tr-TR': 'tr',
    },
    siteUrl: {
        'en-CA': 'ca/en',
        'fr-CA': 'ca/fr',
        'en-US': 'us/en',
        'en-AU': 'au',
        'tr-TR': 'tr',
    },
    mapLangSelector: {
        'en-GB': 'Great Britain (English)',
        de: 'Germany',
        'en-US': 'United States (English)',
        'en-CA': 'Canada (English)',
        'fr-CA': 'Canada (French)',
        'tr-TR': 'Turkey (Turkish)',     
    },
    mapAddressCountry: {
        'en-GB': 'United Kingdom',
        de: 'Germany',
        'en-US': 'United States of America',
        'en-CA': 'Canada (English)',
        'fr-CA': 'Canada (French)',
        'tr-TR': 'Turkey (Turkish)',
    },
    mapFetchifyCode: {
        de: 'DEU',
        'en-US': 'USD',
      
    },
    mapLinks: {
        Wellbeing: {
            'en-GB': 'wellbeing-articles',
            de: 'dein-wohlbefinden',
        },
        Recipes: {
            'en-GB': 'recipes',
            de: 'rezepte',
        },
        News: {
            'en-GB': 'news',
            de: 'news',
        },
    },
    userCentric: {
        'en-CA': 'mDcgfMin6',
        'fr-CA': 'mDcgfMin6',
        'en-US': '_Yffw1vwJ',
        'tr-TR': 'ghKEZd6-1',
    },
    zendeskCookieLang: {
        'de': 'de',
        'en-US': 'en',
        'es-US': 'es',
        'en-CA': 'en',
        'fr-CA': 'fr',
        'tr-TR': 'tr',
        'ja-JP': 'ja',
        
    },
    zendeskChatDept: {
        'en-US': "United States of America",
        'en-GB': "United Kingdom",
    },
    zendeskGoLive: {
        'en-US': 'United States of America',
        'tr-TR': 'Turkey (Turkish)',
        'en-CA': 'Canada (English)',
        }
}