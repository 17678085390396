import React, {
    useEffect, useReducer, useState
} from 'react'

export const LocaleContext = React.createContext({ locale: 'en-EG', multiLang: [] })

export const FilterContext = React.createContext(false)

export function FiltersProvider(props) {
    const [formatFilter, setFormatFilter] = useState([])
    const [teaTypesFilter, setTeaTypesFilter] = useState([])
    const [teaBenefitFilter, setTeaBenefitFilter] = useState([])
    const [flavorFilter, setFlavorFilter] = useState([])
    const [highlightsFilter, setHighlightsFilter] = useState([])

    const values = {
        formatFilter,
        setFormatFilter,
        teaTypesFilter,
        setTeaTypesFilter,
        teaBenefitFilter,
        setTeaBenefitFilter,
        flavorFilter,
        setFlavorFilter,
        highlightsFilter,
        setHighlightsFilter,
    }

    return (
        <FilterContext.Provider
            value={{ ...values }}
        >
            {props.children}
        </FilterContext.Provider>

    )

}