exports.components = {
  "component---src-templates-article-tsx": () => import("./../../../src/templates/Article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-category-products-tsx": () => import("./../../../src/templates/CategoryProducts.tsx" /* webpackChunkName: "component---src-templates-category-products-tsx" */),
  "component---src-templates-generic-tsx": () => import("./../../../src/templates/Generic.tsx" /* webpackChunkName: "component---src-templates-generic-tsx" */),
  "component---src-templates-help-center-tsx": () => import("./../../../src/templates/HelpCenter.tsx" /* webpackChunkName: "component---src-templates-help-center-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/Home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-page-not-found-tsx": () => import("./../../../src/templates/PageNotFound.tsx" /* webpackChunkName: "component---src-templates-page-not-found-tsx" */),
  "component---src-templates-page-search-tsx": () => import("./../../../src/templates/PageSearch.tsx" /* webpackChunkName: "component---src-templates-page-search-tsx" */),
  "component---src-templates-page-sign-up-tsx": () => import("./../../../src/templates/PageSignUp.tsx" /* webpackChunkName: "component---src-templates-page-sign-up-tsx" */),
  "component---src-templates-page-sitemap-tsx": () => import("./../../../src/templates/PageSitemap.tsx" /* webpackChunkName: "component---src-templates-page-sitemap-tsx" */),
  "component---src-templates-products-tsx": () => import("./../../../src/templates/Products.tsx" /* webpackChunkName: "component---src-templates-products-tsx" */),
  "component---src-templates-recipe-tsx": () => import("./../../../src/templates/Recipe.tsx" /* webpackChunkName: "component---src-templates-recipe-tsx" */),
  "component---src-templates-thank-you-tsx": () => import("./../../../src/templates/ThankYou.tsx" /* webpackChunkName: "component---src-templates-thank-you-tsx" */),
  "component---src-templates-wheretobuy-tsx": () => import("./../../../src/templates/Wheretobuy.tsx" /* webpackChunkName: "component---src-templates-wheretobuy-tsx" */)
}

