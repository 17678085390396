import React from "react";
import { useLocale } from "../../hooks";
import { useStaticQuery, graphql } from 'gatsby'
export const useDictionaryQuery = () => {
    const locale = useLocale();
    const {
        allContentfulGlobalDictionary: { edges }
    } = useStaticQuery(
        graphql`
            query globalDictionary{
                allContentfulGlobalDictionary{
                    edges{
                        node{
                            node_locale
                            backButton
                            productsFullCharacteristics
                            productPageOtherProducts
                            readMore
                            shopNowButton
                            findOutMoreButton
                            quickViewButton
                            loadMoreProducts
                            discoverAWorld
                            exploreOurDailyRecipes
                            whatYouWillNeed
                            ingredients
                            methodRecipePage
                            portionsRecipe:portions
                            standbyRecipe:standby
                            prepTimeRecipe:prepTime
                            servesRecipe:serves
                            totalTimeRecipe:totalTime
                            nutritionalInformation
                            energyKcal
                            caloriesRecipe
                            totalFatRecipe
                            transFatRecipe
                            cholesterolRecipe
                            sodiumRecipe
                            totalCarbsRecipe
                            dietaryFiberRecipe
                            totalCaloriesRecipe
                            carbohydrates,
                            saturatedFat
                            sugars
                            protein
                            calcium
                            iron
                            vitaminC
                            vitaminD
                            vitaminA
                            saltG
                            recipeCategoryTitle
                            contactUs
                            helpCenterHeading
                            wellbeingHeading
                            worldOfTeaHeading
                            ourTeasHeading
                            recipesHeading
                            sustainabilityHeading
  
                        }
                    }
                }
            } 
       `
    )

    const localeData = locale
        ? edges.filter(({ node }) => node.node_locale === locale)
        : edges.filter(({ node }) => node.node_locale === 'en-EG' && node.node_locale === 'es-PE')
    const site = localeData.reduce((acc, val) => ({ ...acc, ...val.node }), {})
    return site

}