import React, { useEffect, useState } from "react"
import { useLocale, useLocalPath, useSiteQuery } from "../../../hooks"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { SearchIcon } from "../../../svgs/play"
import * as styles from "./header.module.scss"
import { useUrl } from "../../../hooks/layout/useUrl"
import { useSearchQuery } from "../../../hooks/layout/useSearchQuery"
import { useWindowSize } from "react-use"
import Helmet from 'react-helmet'
import LogoImg from '../../../../static/lipton-assets/logo.webp'

const Header = () => {
  const { headerMenu, headerMenuRight, searchPageSlug, logo } = useSiteQuery()
  const { headerWidget } = useSearchQuery()
  const locale = useLocale()

  const [showMenu, setShowMenu] = useState(false)

  const baseUrl = useUrl()
  const [mobileMenu, setMobileMenu] = useState(false)

  const { width: windowWidth } = useWindowSize()
  const [navbar, setNavbar] = useState(false)

  const bgColor = () => {
    if (window.scrollY >= 100) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  const externalLink = (link) => {
    return (link.split('/').includes('https:') || link.split('/').includes('http:'))
}

  useEffect(() => {
    // if (locale == "en-US" || locale==='nl-NL') {
    if (locale == "en-US") {
      window.addEventListener("scroll", bgColor)
      return () => {
        window.removeEventListener("scroll", bgColor)
      }
    }
  }, [])

  const dropDownMenu = locale == "en-US" || locale === "nl-NL" || locale === "en-EG" || locale === "en-AE" || locale === "pl-PL" || locale === "ar-AE" || locale === "ar-EG" || locale === "es-CL";

  return (
    <header className={` ${navbar ? styles.fixed_navigation : ""}`}>
      {locale == "en-US" ? (
        <div className={styles.paragraphText}>
          {headerWidget.map((items: any, index: number) => {
            const { title, slug } = items
            const newSLug = slug[0] === "/" ? slug.slice(1) : slug
            return (
              <button className={styles.label} key={index}>
                <Link to={useLocalPath(`/${newSLug}`)}>{title}</Link>
              </button>
            )
          })}
        </div>
      ) : null}

      <div className={`${styles.header_menu} ${styles.desktop_only}`}>
        <Link to={useLocalPath("")} className={styles.logo}>
        {
            locale === 'en-US'
                ? <img src={LogoImg} className={styles.logo} alt={logo.image[0].title} />
                : <Img fluid={logo.image[0].fluid} alt={logo.image[0].title}></Img>
        }
        </Link>

        <nav role="navigation" className={styles.navigation}>
          <ul className={`${styles.col_left} ${`styles.headerLeft`}`}>
            {headerMenu?.map((items: any, index: number) => {
              const { title, slug, subMenuLink } = items
              const newSLug = slug[0] === "/" ? slug.slice(1) : slug
              if (subMenuLink === null) {
                if(externalLink(slug)){
                  return (
                    <li key={index} >
                        <a href={slug} target="_blank">
                            {title}
                        </a>
                    </li>
                )}else{
                  return (
                    <li key={index}>
                      <Link to={useLocalPath(`/${newSLug}`)}>{title}</Link>
                    </li>
                  )
                }
              }else {
              return (
                <li
                  onMouseOver={() => setShowMenu(true)}
                  onMouseLeave={() => setShowMenu(false)}
                  onFocus={() => setShowMenu(true)}
                >
                  <div className={styles.subListCategory}>
                    <Link to={useLocalPath(`/${newSLug}`)} className={styles.parent}>{title}</Link>
                    {dropDownMenu
                      ? showMenu &&
                        subMenuLink && (
                          <div className={styles.subNavItem}>
                            {subMenuLink.map((link, id) => (
                              <div className={styles.navurl} key={id}>
                                <Link to={useLocalPath(`/${link.slug}`)}>
                                  {link.title}
                                </Link>
                              </div>
                            ))}
                          </div>
                        )
                      : ""}
                  </div>
                </li>
              )}
            })}
          </ul>

          <ul className={`${styles.col_right} ${`styles.headerRight`}`}>
            {headerMenuRight.map((items: any, index: number) => {
              const { title, slug, subMenuLink } = items
              const newSLug = slug[0] === "/" ? slug.slice(1) : slug
              // return (
              //   <li key={index}>
              //     <Link to={useLocalPath(`/${newSLug}`)}>{title}</Link>
              //   </li>
              // )

              if (subMenuLink === null) {
                if(externalLink(slug)){
                  return (
                    <li key={index} >
                        <a href={slug} target="_blank">
                            {title}
                        </a>
                    </li>
                )}else{
                  return (
                    <li key={index}>
                      <Link to={useLocalPath(`/${newSLug}`)}>{title}</Link>
                    </li>
                  )
                }
              }else {
              return (
                <li
                  onMouseOver={() => setShowMenu(true)}
                  onMouseLeave={() => setShowMenu(false)}
                  onFocus={() => setShowMenu(true)}
                >
                  <div className={styles.subListCategory}>
                    <Link to={useLocalPath(`/${newSLug}`)} className={styles.parent}>{title}</Link>
                    {dropDownMenu
                      ? showMenu &&
                        subMenuLink && (
                          <div className={styles.subNavItem}>
                            {subMenuLink.map((link, id) => (
                              <div className={styles.navurl} key={id}>
                                <Link to={useLocalPath(`/${link.slug}`)}>
                                  {link.title}
                                </Link>
                              </div>
                            ))}
                          </div>
                        )
                      : ""}
                  </div>
                </li>
              )}


            })}
          </ul>

          {searchPageSlug && (
            <Link
              className={styles.btn__search}
              to={useLocalPath(`/${searchPageSlug}`)}
            >
              <span className="sr-only">Search</span>
              <SearchIcon className={styles.btn__search} />
            </Link>
          )}
        </nav>
      </div>

      {/* mobile navigation  */}
      {windowWidth < 1025 && (
        <div className={`${styles.header_menu} ${styles.mobile_only}`}>
          <nav role="navigation" className={styles.navigation}>
            <div className={`${styles.menuToggle}`}>
              <label htmlFor="toggle" className="sr-only">Mobile Navigation</label>
              <input type="checkbox" id="toggle" />
              <span></span>
              <span></span>
              <span></span>
              <ul
                className={`${styles.menu}`}
                //  onClick={() => {
                //    document.getElementById("toggle").checked = false
                //  }}
              >
                {headerMenu.map((items: any, index: number) => {
                  const { title, slug, subMenuLink } = items
                  const newSLug = slug[0] === "/" ? slug.slice(1) : slug
                  if (subMenuLink === null && !externalLink(slug)) {
                    return (
                      <li
                        onClick={() => {
                          document.getElementById("toggle").checked = false
                        }}
                        key={index}
                      >
                        <Link to={useLocalPath(`/${newSLug}`)}>{title}</Link>
                      </li>
                    )
                  }else if (subMenuLink === null && externalLink(slug)){
                    return (
                      <li key={index} >
                          <a href={slug} target="_blank">
                              {title}
                          </a>
                      </li>)
                  } else{
                    return (
                      <li>
                        <div className={styles.subListCategory}>
                            <a
                            className={`${styles.mobileMenu}${styles.parent}`}
                            // to={useLocalPath(`/${slug}`)}
                            href="#"
                            onClick={(event) => {
                            event.preventDefault();
                            setMobileMenu((prevMobileMenu) => !prevMobileMenu);
                        }}
                    > {title}</a>          
                          {dropDownMenu
                            ? subMenuLink &&
                              mobileMenu && (
                                <div className={styles.subNavItemMobile}>
                                  {subMenuLink.map((link, id) => (
                                    <div
                                      onClick={() => {
                                        document.getElementById(
                                          "toggle"
                                        ).checked = false
                                      }}
                                      className={styles.navurlMobile}
                                      key={id}
                                    >
                                      <Link to={useLocalPath(`/${link.slug}`)}>
                                        {link.title}
                                      </Link>
                                    </div>
                                  ))}
                                  {/* <div
                                    className={styles.closeButton}
                                    onClick={() => setMobileMenu(false)}
                                  >
                                    X
                                  </div> */}
                                </div>
                              )
                            : ""}
                        </div>
                      </li>
                    )
                  }
                })}

                {headerMenuRight.map((items: any, index: number) => {
                  const { title, slug, subMenuLink } = items
                  const newSLug = slug[0] === "/" ? slug.slice(1) : slug
                  // return (
                  //   <li
                  //     onClick={() => {
                  //       document.getElementById("toggle").checked = false
                  //     }}
                  //     key={index}
                  //   >
                  //     <Link to={useLocalPath(`/${newSLug}`)}>{title}</Link>
                  //   </li>
                  // )

                  if (subMenuLink === null && !externalLink(slug)) {
                    return (
                      <li
                        onClick={() => {
                          document.getElementById("toggle").checked = false
                        }}
                        key={index}
                      >
                        <Link to={useLocalPath(`/${newSLug}`)}>{title}</Link>
                      </li>
                    )
                  }else if (subMenuLink === null && externalLink(slug)){
                    return (
                      <li key={index} >
                          <a href={slug} target="_blank">
                              {title}
                          </a>
                      </li>)
                  } else{
                    return (
                      <li>
                        <div className={styles.subListCategory}>
                          <Link
                            className={`${styles.mobileMenu}${styles.parent}`}
                            to={useLocalPath(`/${slug}`)}
                            onClick={() => {
                              setMobileMenu(true)
                            }}
                          >
                            {title}{" "}
                          </Link>
                          {dropDownMenu
                            ? subMenuLink &&
                              mobileMenu && (
                                <div className={styles.subNavItemMobile}>
                                  {subMenuLink.map((link, id) => (
                                    <div
                                      onClick={() => {
                                        document.getElementById(
                                          "toggle"
                                        ).checked = false
                                      }}
                                      className={styles.navurlMobile}
                                      key={id}
                                    >
                                      <Link to={useLocalPath(`/${link.slug}`)}>
                                        {link.title}
                                      </Link>
                                    </div>
                                  ))}
                                  <div
                                    className={styles.closeButton}
                                    onClick={() => setMobileMenu(false)}
                                  >
                                    X
                                  </div>
                                </div>
                              )
                            : ""}
                        </div>
                      </li>
                    )
                  }
                })}
                {locale == "en-US"
                  ? headerWidget.map((items: any, index: number) => {
                      const { title, slug } = items
                      const newSLug = slug[0] === "/" ? slug.slice(1) : slug
                      return (
                        <li
                          onClick={() => {
                            document.getElementById("toggle").checked = false
                          }}
                          key={index}
                        >
                          <Link to={useLocalPath(`/${newSLug}`)}>{title}</Link>
                        </li>
                      )
                    })
                  : ""}
              </ul>
            </div>
          </nav>
          <Link to={`/${baseUrl}/`} className={styles.logo}>
            <div className={styles.logo}>
              <Img
                fluid={logo.image[0].fluid}
                className={styles.logo}
                alt={logo.image[0].title}
              ></Img>
            </div>
          </Link>
          {searchPageSlug && (
            <button className={styles.btn__search} type="button" aria-label="">
              <Link to={useLocalPath(`/${searchPageSlug}`)}>
                <span className="sr-only">Search</span>
                <SearchIcon className={styles.btn__search} />
              </Link>
            </button>
          )}
        </div>
      )}
    </header>
  )
}

export default Header
