import React from 'react';
import Layout from './src/components/Layout/Layout';
import Scripts from './src/components/Layout/Scripts/Scripts';
import { LocaleContext, FiltersProvider } from './src/utils/context';

export const wrapPageElement = ({ element, props: { pageContext: { site, node_locale, multiLang } } }) => {
  return (
    <FiltersProvider>
      <LocaleContext.Provider value={{ locale: node_locale, multiLang }}>
        <>
          <Scripts />
          <Layout>
            {element}
          </Layout>
        </>
      </LocaleContext.Provider>
    </FiltersProvider>
  );
};

// Implementing onRouteUpdate
export const onRouteUpdate = ({ location, prevLocation }) => {
  // console.log("Navigating from", prevLocation ? prevLocation.pathname : "nowhere", "to", location.pathname);
  // Custom logic to run on route update can go here
  // For example, initializing or destroying plugins, analytics, etc.
};

// Implementing shouldUpdateScroll
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  // Scroll to top on new pages, maintain position on back navigation
  if (location.action === 'PUSH') {
    window.scrollTo(0, 0);
    return false; // Returning false prevents the default behavior
  }

  const savedPosition = getSavedScrollPosition(location);
  return savedPosition || true; // Use saved position or true for default behavior
};
